import http from "@/utils/http";

export async function admin_login(data) {
  const req = (await http.post('/auth/admin/login',data));
  return req.data;   
}

export async function verify_otp(data) {
  const req = (await http.post('/auth/admin/verify-otp',data));
  return req.data;   
}

export async function resend_otp(data) {
  const req = (await http.get(`/auth/admin/resend-mfa-token/${data}`));
  return req.data;   
}